import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "video"
    }}>{`Video`}</h1>
    <p>{`Embed in page`}</p>
    <p>{`Img as button, Screen snap of one frame with a UHS Design Standard Button `}{`[btn]`}{` for YouTube as overlay.`}</p>
    <p>{`View Video with on click, open video in modal for viewing. Grey out background in margins add X top right to close.`}</p>
    <p>{`How to prevent black bars in different browsers?`}</p>
    <p>{`Template for cover slide to prevent cropping?`}</p>
    <p>{`Use of cover slides`}</p>
    <p>{`In-page or external links?`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      